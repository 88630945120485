import {FieldKeys} from "./types.form.js";

export function isFormJson(obj: unknown): obj is FormJSON {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }
    return Object.hasOwn(obj, "v")
}

export type FormJSON = FormJsonV1
export type FormJsonV1 = {
    "v": 0 | 1;
    "steps": FormFields[]
    "end"?: string
}
type FormFields = { fields: (FormField | FormButton)[] }

export type FormField = {
    type: FieldKeys,
    content?: string,
    required?: "y" | "n";
}

export type FormButton = {
    type: ButtonTypes,
    content?: string
}

export function isFormButton(obj: FormField | FormButton): obj is FormButton {
    return obj.type === "next" || obj.type === "submit";
}

export type ButtonTypes = "next" | "submit"
