import {CheckReturn, DomainParts} from "./util.js";
import {FormJSON, isFormJson} from "./types.formJson.js";

const formStyles: {[index: string]: HTMLLinkElement} = {}
export function loadCss(whichCss: "main" | "custom", domain: DomainParts, source: string | null) {
    const location = "main" === whichCss
            ? `${domain.mainHost}/CustomForm.css`
            : source
                ? `${domain.customHost}/${source}-style.css`
                : `${domain.customHost}/style.css`

    if (Object.hasOwn(formStyles, location)) return formStyles[location]

    const linkElem = document.createElement("link");
    linkElem.setAttribute("rel", "stylesheet");
    linkElem.setAttribute("href", location);
    formStyles[location] = linkElem
    return formStyles[location]
}

const formStructs: {[index: string]: Promise<CheckReturn<FormJSON>>} = {}
export async function loadJson(domain: DomainParts, source: string | null, strict: boolean) {
    const url  = source
        ? `${domain.customHost}/${source}-struct.json`
        : `${domain.customHost}/struct.json`

    if (Object.hasOwn(formStructs, url)) return formStructs[url]

    formStructs[url] = fetchJson(url, strict)
    return formStructs[url]
}

const defaultForm: FormJSON = {
    v: 1,
    steps: [
        {
            fields: [
                {
                    type: "title",
                    content: "Sign Up to Receive Our Newsletter"
                },
                {type: "firstName"},
                {type: "lastName"},
                {type: "email"},
                {type: "canEmail", content: "By checking this box, you agree to our Terms of Use and acknowledge that we may collect and use your data pursuant to our Privacy Policy."},
                {type: "next"},
                {type: "blurb2", content: "You agree to receive communications from us. You can opt out of email communications anytime by using the “Email Preferences” link at the bottom of every email."}
            ]
        },
        {
            fields: [
                {
                    type: "title",
                    content: "Get your questions answered! Connect with a Local Professional"
                },
                {type: "phone"},
                {type: "zip"},
                {type: "submit"},
                {type: "blurb2", content: "By selecting the link above, you acknowledge the Terms of Service. We are not affiliated with or endorsed by any government agency's Office of Personnel Management. Affiliated professionals provide insurance, annuity, and financial products unrelated to our services. By entering your information, you consent to being contacted by a professional via the provided phone number or email."}
            ]
        }
    ]
}

async function fetchJson(url: string, strict: boolean): Promise<CheckReturn<FormJSON>> {
    try {
        const response = await fetch(url)
        if (response.status >= 400) {
            return {
                ok: false,
                data: defaultForm
            }
        }
        const json = await response.json()
        if (!isFormJson(json)) throw new Error("uh oh, this isn't a form")
        return {
            ok: true,
            data: json
        }
    } catch (e) {
        console.error(e)
        return {
            ok: false,
            data: defaultForm
        }
    }
}