import {globalStyle, style} from "@vanilla-extract/css";
import {centerVertical, circle, inputBoxes, symbol} from "./base.css.js";
import {vars} from "./vars.css.js";

/**
 * ELEMENT CLASSES
 */
export const textClass = style({})
export const emailClass = style({})
export const dateClass = style({})
export const dropdownClass = style({})
export const telClass = style({})
export const checkboxClass = style({
    position: "relative",
    accentColor: `var(${vars.color.markerValidBg})`,
})
export const dropdownCaretClass = style({
    ...centerVertical,
    color: `var(${vars.color.fieldTextInactive})`,
    position: "absolute",
    pointerEvents: "none",
    right: "0.5em",
    width: "20px",
    aspectRatio: "1/1",
    selectors: {
        [`&::after`]: {
            content: "\\1F893",
            display: "flex",
            width: "20px",
            aspectRatio: "1/1",
            position: "absolute",
            fontSize: "30px",
        },
    }
})

export const fieldContainerClass = style({
    width: "100%",
    position: "relative",
    marginBlockEnd: `var(${vars.margin.inner.blockEnd})`,
    selectors: {
        [`&:last-child`]: {
            marginBlockEnd: "unset"
        },

        "&:has(#homePageUrl)": {
            display: "none",
        },

        // required circle
        [`&:has(input[required]:placeholder-shown)::before,
        &:has(${dropdownClass}[required] option:first-child:checked)::before,
        &:has(input[required]${dateClass}:not(:valid))::before`]: {
            ...circle,
            content: "",
            background: `var(${vars.color.markerRequiredBg})`,
            border: `var(${vars.border.markerRequired})`,
        },
        // valid circle
        [`&:has(input:valid:not(:placeholder-shown,
        ${checkboxClass}))::before,
        &:has(${dropdownClass} option:first-child:not(:checked))::before`]: {
            ...circle,
            content: "",
            background: `var(${vars.color.markerValidBg})`,
            border: `1px solid var(${vars.color.markerValidBg})`,
        },
        // invalid circle
        [`&:has(input:invalid:not(:placeholder-shown, ${checkboxClass}, ${dateClass}))::before`]: {
            ...circle,
            content: "",
            background: `var(${vars.color.markerInvalidBg})`,
            border: `1px solid var(${vars.color.markerInvalidBg})`,
        },


        // valid symbol
        [`&:has(input:valid:not(:placeholder-shown, ${checkboxClass}))::after,
        &:has(${dropdownClass} option:first-child:not(:checked))::after`]: {
            ...circle,
            ...symbol,
            content: "\\2713",
            color: `var(${vars.color.markerValid})`,
        },
        // invalid symbol
        [`&:has(input:invalid:not(:placeholder-shown, ${checkboxClass}, ${dateClass}))::after`]: {
            ...circle,
            ...symbol,
            content: "\\2717",
            color: `var(${vars.color.markerInvalid})`,
        },
    }
})

export const asteriskClass = style({
    display: "none",
    position: "absolute",
    selectors: {
        [`input[required]:placeholder-shown ~ &,
        ${dropdownClass}[required]:has(option:first-child:checked) ~ &,
        input[required]${dateClass}:not(:valid) ~ &`]: {
            display: "block",
            left: 0,
            top: "50%",
            transform: "translate(calc(-50% + 0.5px), -50%)",
            stroke: `var(${vars.color.markerRequired})`,
            strokeWidth: "2px",
        }
    }
})

/**
 * INPUT STYLES
 */
globalStyle(`${fieldContainerClass} input:not(${checkboxClass}):focus, ${fieldContainerClass} select:focus`, {
    background: `var(${vars.color.formBgInnerActive})`,
    border: `var(${vars.border.fieldActive})`,
    color: `var(${vars.color.fieldTextActive})`,
})
globalStyle(`input:focus-visible, select:focus-visible`, {
    outline: "none",
})
globalStyle(`select, input`, {
    ...inputBoxes,
    "@media": {
        "screen and (max-width: 768px)": {
            padding: "0.5em .7em",
            fontSize: `calc(var(${vars.font.size.formMain}) * 1.5)`,
        }
    }
})

globalStyle(`select`, {
    appearance: "none",
    "@media": {
        "screen and (max-width: 768px)": {
            fontSize: `calc(var(${vars.font.size.formMain}) * 1.5 + 1px)`,
        }
    }
})
globalStyle(`select, input:not(${checkboxClass})`, {
    boxShadow: `var(${vars.shadow.formInner})`,
    background: `var(${vars.color.formBgInner})`,
    border: `var(${vars.border.fieldInactive})`,
    color: `var(${vars.color.fieldTextInactive})`,
})
globalStyle(`${fieldContainerClass}:has(~ button:hover):has(:invalid) input,
${fieldContainerClass}:has(~ button:hover):has(:invalid) label${checkboxClass},
${fieldContainerClass}:has(~ button:hover):has(:invalid) select`, {
    background: `var(${vars.color.markerInvalidBg})`,
})

/**
 * INFO STYLES
 */
export const titleClass = style({
    textAlign: "center",
    margin: "unset",
    color: `var(${vars.color.fontPrimary})`,
    lineHeight: `var(${vars.font.lineHeight.title})`,
    fontSize: `calc(var(${vars.font.size.formMain}) * 2.5)`,
    "@media": {
        'screen and (max-width: 768px)': {
            fontSize: `calc(var(${vars.font.size.formMain}) * 1.8)`,
        },
    },
})
export const subtitleClass = style({
    textAlign: "center",
    margin: "unset",
    color: `var(${vars.color.fontPrimary})`,
    lineHeight: `var(${vars.font.lineHeight.subtitle})`,
    fontSize: `calc(var(${vars.font.size.formMain}) * 1.8)`,
    "@media": {
        'screen and (max-width: 768px)': {
            fontSize: `calc(var(${vars.font.size.formMain}) * 1.2)`,
        },
    },
})
export const blurbClass = style({
    textAlign: "center",
    margin: "unset",
    color: `var(${vars.color.fontSecondary})`,
})
export const blurb2Class = style({
    textAlign: "center",
    margin: "unset",
    color: `var(${vars.color.fontTertiary})`,
    fontSize: `calc(var(${vars.font.size.formMain}) * 0.75)`,
    "@media": {
        "screen and (max-width: 768px)": {
            paddingInline: "0.5em",
            paddingBlockStart: "0.1em",
            fontSize: `calc(var(${vars.font.size.formMain}) * 0.85) !important`,
        }
    }
})

/**
 * CHECKBOX STYLES
 */
globalStyle(`input[type="checkbox"]`, {
    width: `var(${vars.width.checkbox})`,
    aspectRatio: "1/1",
    margin: 0,
})
globalStyle(`div${checkboxClass}`, {
    display: "flex",
    alignItems: "center",
})
globalStyle(`label${checkboxClass} `, {
    "@media": {
        'screen and (max-width: 768px)': {
            fontSize: `calc(var(${vars.font.size.formMain}) * 0.8)`,
        },
    },
})

/**
 * CONTAINER CLASSES
 */
export const textConClass = style({
    marginBlock: `var(${vars.margin.field.block})`
})
export const emailConClass = style({
    marginBlock: `var(${vars.margin.field.block})`
})
export const dateConClass = style({
    marginBlock: `var(${vars.margin.field.block})`
})
export const dropdownConClass = style({
    marginBlock: `var(${vars.margin.field.block})`
})
export const telConClass = style({
    marginBlock: `var(${vars.margin.field.block})`
})
export const titleConClass = style({
    marginBlockEnd: `var(${vars.margin.title.blockEnd})`,
})
export const subtitleConClass = style({
    marginBlockEnd: `var(${vars.margin.subtitle.blockEnd})`,
})
export const blurbConClass = style({})
export const checkboxConClass = style({
    display: "grid",
    gridTemplateColumns: `auto 1fr`,
    gap: "0.5em",
    marginBlock: `var(${vars.margin.field.block})`
})
