import {
    BlurbMeta,
    FieldKeys,
    FieldMeta,
    Fields,
    InputCheckboxMeta,
    InputDateMeta,
    InputDropdownMeta,
    InputEmailMeta,
    InputTelephoneMeta,
    InputTextMeta, SubtitleMeta,
    TitleMeta
} from "./types.form.js";
import {buildInfoEl, buildInputEl, buildSelectEl} from "./build.js";
import {
    blurb2Class,
    blurbClass, blurbConClass,
    checkboxClass, checkboxConClass,
    dateClass, dateConClass,
    dropdownClass, dropdownConClass,
    emailClass, emailConClass, subtitleClass, subtitleConClass,
    telClass, telConClass, textClass, textConClass,
    titleClass, titleConClass
} from "./css/field.css.js";

const currentYear = new Date().getFullYear()
const year0 = currentYear.toString()
const year1 = (currentYear + 1).toString()
const year2 = (currentYear + 2).toString()
const year3 = (currentYear + 3).toString()
const year4 = (currentYear + 4).toString()

type PickBase<T extends FieldMeta> = Pick<T, "builder" | "elClassName" | "containerClassName" | "type" | "elTag" | "makeEl">
type OmitBase<T extends FieldMeta> = Omit<T, "default" | "el">

const telBase: PickBase<InputTelephoneMeta> = {
    builder: buildInputEl,
    elClassName: telClass,
    containerClassName: telConClass,
    type: "tel",
    elTag: "input",
    makeEl: () => document.createElement("input"),
}
const textBase: PickBase<InputTextMeta> = {
    builder: buildInputEl,
    elClassName: textClass,
    containerClassName: textConClass,
    type: "text",
    elTag: "input",
    makeEl: () => document.createElement("input"),
}
const dateBase: PickBase<InputDateMeta> = {
    builder: buildInputEl,
    elClassName: dateClass,
    containerClassName: dateConClass,
    type: "date",
    elTag: "input",
    makeEl: () => document.createElement("input"),
}

const dropBase: PickBase<InputDropdownMeta> = {
    builder: buildSelectEl,
    elClassName: dropdownClass,
    containerClassName: dropdownConClass,
    type: "dropdown",
    elTag: "select",
    makeEl: () => document.createElement("select"),
}

const titleBase: PickBase<TitleMeta> = {
    builder: buildInfoEl,
    elClassName: titleClass,
    containerClassName: titleConClass,
    type: "title",
    elTag: "h3",
    makeEl: () => document.createElement("h3"),
}

const subtitleBase: PickBase<SubtitleMeta> = {
    builder: buildInfoEl,
    elClassName: subtitleClass,
    containerClassName: subtitleConClass,
    type: "subtitle",
    elTag: "h4",
    makeEl: () => document.createElement("h4"),
}

const blurbBase: Omit<PickBase<BlurbMeta>, "elClassName"> = {
    builder: buildInfoEl,
    containerClassName: blurbConClass,
    type: "blurb",
    elTag: "p",
    makeEl: () => document.createElement("p"),
}

const checkBase: PickBase<InputCheckboxMeta> = {
    builder: buildInputEl,
    elClassName: checkboxClass,
    containerClassName: checkboxConClass,
    type: "checkbox",
    elTag: "input",
    makeEl: () => document.createElement("input"),
}

const emailBase: OmitBase<InputEmailMeta> = {
    builder: buildInputEl,
    elClassName: emailClass,
    containerClassName: emailConClass,
    type: "email",
    elTag: "input",
    makeEl: () => document.createElement("input"),
    pattern: "[\\-\\w\\._\\+]+@[\\-\\w\\._]+\\.[\\-\\w\\._]{2,}",
    maxLength: 50,
    minLength: 8,
    validationMessage: {
        pattern: "must be formatted as an email",
    },
}

const nameBase: OmitBase<InputTextMeta> = {
    ...textBase,
    pattern: "[a-zA-Z\\s]+",
    maxLength: 25,
    minLength: 2,
    validationMessage: {
        pattern: "must be only letters",
    },
}

const telephoneBase: OmitBase<InputTelephoneMeta> = {
    ...telBase,
    pattern: "[0-9]+",
    maxLength: 10,
    minLength: 10,
    validationMessage: {
        pattern: "must be only numbers",
    },
}

const addressBase: OmitBase<InputTextMeta> = {
    ...textBase,
    pattern: "[a-zA-Z0-9\\s\\-]+",
    maxLength: 50,
    minLength: 5,
    validationMessage: {
        pattern: "must be only letters, numbers, and -",
    },
}

export const fieldDefaults: { [key in FieldKeys]: Fields[key] } = {
    title: {
        ...titleBase,
        default: "Please enter your details"
    },
    subtitle: {
        ...subtitleBase,
        default: ""
    },
    blurb: {
        ...blurbBase,
        elClassName: blurbClass,
        default: "",
    },
    blurb2: {
        ...blurbBase,
        elClassName: blurb2Class,
        default: "",
    },
    homePageUrl: {
        ...textBase,
        validationMessage: {
            pattern: "",
        },
        pattern: ".+",
        maxLength: 200,
        minLength: 0,
        default: "Home page url",
    },
    firstName: {
        ...nameBase,
        default: "First name",
    },
    lastName: {
        ...nameBase,
        default: "Last name",
    },
    email: {
        ...emailBase,
        default: "Email",
    },
    workEmail: {
        ...emailBase,
        default: "Work email",
    },
    personalEmail: {
        ...emailBase,
        default: "Personal email",
    },
    phone: {
        ...telephoneBase,
        default: "Phone number",
    },
    cellPhone: {
        ...telephoneBase,
        default: "Mobile number",
    },
    resPhone: {
        ...telephoneBase,
        default: "Residential phone number",
    },
    dob: {
        ...dateBase,
        default: "Date of birth",
    },
    addr1: {
        ...addressBase,
        default: "Street address",
    },
    addr2: {
        ...addressBase,
        default: "Street address line 2",
    },
    city: {
        ...addressBase,
        default: "City",
    },
    state: {
        ...dropBase,
        options: [
            {"label": "Alabama", "value": "AL"},
            {"label": "Alaska", "value": "AK"},
            {"label": "Arizona", "value": "AZ"},
            {"label": "Arkansas", "value": "AR"},
            {"label": "California", "value": "CA"},
            {"label": "Colorado", "value": "CO"},
            {"label": "Connecticut", "value": "CT"},
            {"label": "Delaware", "value": "DE"},
            {"label": "Florida", "value": "FL"},
            {"label": "Georgia", "value": "GA"},
            {"label": "Hawaii", "value": "HI"},
            {"label": "Idaho", "value": "ID"},
            {"label": "Illinois", "value": "IL"},
            {"label": "Indiana", "value": "IN"},
            {"label": "Iowa", "value": "IA"},
            {"label": "Kansas", "value": "KS"},
            {"label": "Kentucky", "value": "KY"},
            {"label": "Louisiana", "value": "LA"},
            {"label": "Maine", "value": "ME"},
            {"label": "Maryland", "value": "MD"},
            {"label": "Massachusetts", "value": "MA"},
            {"label": "Michigan", "value": "MI"},
            {"label": "Minnesota", "value": "MN"},
            {"label": "Mississippi", "value": "MS"},
            {"label": "Missouri", "value": "MO"},
            {"label": "Montana", "value": "MT"},
            {"label": "Nebraska", "value": "NE"},
            {"label": "Nevada", "value": "NV"},
            {"label": "New Hampshire", "value": "NH"},
            {"label": "New Jersey", "value": "NJ"},
            {"label": "New Mexico", "value": "NM"},
            {"label": "New York", "value": "NY"},
            {"label": "North Carolina", "value": "NC"},
            {"label": "North Dakota", "value": "ND"},
            {"label": "Ohio", "value": "OH"},
            {"label": "Oklahoma", "value": "OK"},
            {"label": "Oregan", "value": "OR"},
            {"label": "Pennsylvania", "value": "PA"},
            {"label": "Rhode Island", "value": "RI"},
            {"label": "South Carolina", "value": "SC"},
            {"label": "South Dakota", "value": "SD"},
            {"label": "Tennessee", "value": "TN"},
            {"label": "Texas", "value": "TX"},
            {"label": "Utah", "value": "UT"},
            {"label": "Vermont", "value": "VT"},
            {"label": "Virginia", "value": "VA"},
            {"label": "Washington", "value": "WA"},
            {"label": "West Virginia", "value": "WV"},
            {"label": "Wisconsin", "value": "WI"},
            {"label": "Wyoming", "value": "WY"}
        ],
        default: "State",
    },
    zip: {
        ...textBase,
        maxLength: 5,
        minLength: 5,
        pattern: "\\d+",
        validationMessage: {
            pattern: "must be only numbers",
        },
        default: "ZIP code",
    },
    whenCanCall: {
        ...dropBase,
        options: [
            {"label": "Morning", "value": "morning"},
            {"label": "Afternoon", "value": "afternoon"},
            {"label": "Evening", "value": "evening"},
        ],
        default: "Best time to call you?",
    },
    yearCanRetire: {
        ...dropBase,
        options: [
            {"label": year0, "value": year0},
            {"label": year1, "value": year1},
            {"label": year2, "value": year2},
            {"label": year3, "value": year3},
            {"label": year4, "value": year4},
            {"label": "Later", "value": "later"},
        ],
        default: "What year can you retire?",
    },
    notes: {
        ...textBase,
        pattern: ".+",
        maxLength: 500,
        minLength: 0,
        validationMessage: {
            pattern: "must be only regular characters",
        },
        default: "Notes", //todo change this
    },
    canEmail: {
        ...checkBase,
        default: "I agree to allow you to send me emails...", //todo change this
    },
    canMarket: {
        ...checkBase,
        default: "I agree to allow you to send me marketing...", //todo change this
    },
}

export function buildMeta<T extends FieldKeys>(key: T): Required<Fields[T]> {
    const part: Fields[T] = fieldDefaults[key]
    // todo improve type inference/safety
    return {
        ...part,
        el: document.createElement(part.elTag)
    } as unknown as Required<Fields[T]>
}