export const stageUrlMap: { [key in Stage]: string } = {
    dev: "https://dyvroskdnbeaq.cloudfront.net/",
    prod: "https://d1h30yw9w53w5m.cloudfront.net/",
}
const stages = ["dev", "prod"] as const
export type Stage = typeof stages[number];
export function isStage(s: string): s is Stage {
    return stages.includes(s as unknown as Stage)
}

const sources = ["cs", "eb", "lms"] as const
export type Source = typeof sources[number];
export function isSource(s: string): s is Source {
    return sources.includes(s as unknown as Source)
}

const forward = ["AMED1", "AMED2", "AMED3", "AMED4", "BFS", "CSM", "FRN", "IUL", "LDR1", "LDR2",
    "LDR3", "PMED1", "PMED2", "PMED3", "PMED4", "PSRE", "RES1", "RES2", "RES3", "RES4", "RES5", "RNN", "FMM" ] as const
export type Forward = typeof forward[number];
export function isForward(fwd: string): fwd is Forward {
    return forward.includes(fwd as unknown as Forward)
}