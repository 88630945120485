import {checkNever} from "./util.js";
import {CustomForm} from "./CustomForm.js";
import {FormButton, FormField} from "./types.formJson.js";
import {
    BlurbMeta,
    FieldMeta,
    InfoMeta,
    InputCheckboxMeta,
    InputDateMeta,
    InputDropdownMeta,
    InputEmailMeta,
    InputMeta,
    InputTelephoneMeta,
    InputTextMeta,
    MetaMap,
    MetaTypes,
    SelectMeta, SubtitleMeta,
    TitleMeta
} from "./types.form.js";
import {buildMeta, fieldDefaults} from "./structures.form.js";
import {subBtnClass} from "./css/button.css.js";
import {asteriskClass, dropdownCaretClass, fieldContainerClass} from "./css/field.css.js";
import {msgClass} from "./css/label.css.js";
import {highlightClass} from "./css/form.css.js";

export function buildInfoEl(meta: Required<InfoMeta>, pkg: Pkg) {
    const condensed = pkg.field.content?.replaceAll(/\s/g, "")
    if (condensed) {
        if (condensed.includes("<script")) throw new Error("script injection detected");
    }
    meta.el.innerHTML = pkg.field.content?.replaceAll("{{highlightClass}}", highlightClass) ?? meta.default
}
export function buildInputEl(meta: Required<InputMeta>, pkg: Pkg) {
    meta.el.id = pkg.field.type
    meta.el.name = pkg.field.type
    meta.el.type = meta.type
    if (pkg.field.type === "homePageUrl") return
    meta.el.required = "n" !== pkg.field.required
}
export function buildSelectEl(meta: Required<SelectMeta>, pkg: Pkg) {
    meta.el.id = pkg.field.type
    meta.el.name = pkg.field.type
    meta.el.required = "n" !== pkg.field.required

    const defaultOpt = document.createElement("option")
    defaultOpt.selected = true

    meta.el.appendChild(defaultOpt)

    for (const option of meta.options) {
        const optEl = document.createElement("option")
        optEl.textContent = option.label
        optEl.value = option.value
        meta.el.appendChild(optEl)
    }

    const caretEl = document.createElement("div")
    caretEl.classList.add(dropdownCaretClass)

    pkg.container.append(caretEl)
}
function buildLabel(this: CustomForm, field: FormField) {
    const label = document.createElement("label")

    const labelHolder = document.createElement("span")
    const labelMsg = document.createElement("span")

    this.labelMessages[field.type] = labelMsg
    labelMsg.classList.add(msgClass)
    labelHolder.textContent = field.content ?? fieldDefaults[field.type].default
    label.append(labelHolder, labelMsg)
    label.htmlFor = field.type
    return {label, labelMsg}
}
export function buildButton(this: CustomForm, btn: FormButton, stepNum: number) {
    const el = document.createElement("button");
    el.classList.add(subBtnClass)
    this.buttons.push(el)

    switch (btn.type) {
        case "next":
            el.innerText = btn.content ?? "Next Step"
            el.addEventListener("pointerdown", async () => await this.handleNextBtn(stepNum))
            el.addEventListener("keydown", async (e) => {
                if (e.key === "Enter") await this.handleNextBtn(stepNum)
            })
            return el
        case "submit":
            el.innerText = btn.content ?? "Submit"
            el.addEventListener("pointerdown", () => this.handleSubmitBtn(stepNum))
            el.addEventListener("keydown", async (e) => {
                if (e.key === "enter") await this.handleSubmitBtn(stepNum)
            })
            return el
        default:
            checkNever(btn.type)
            throw new Error("unknown button type")
    }
}

function packageEl(this: CustomForm, meta: Required<FieldMeta>, pkg: Pkg, step: number) {
    pkg.container.classList.add(fieldContainerClass, meta.containerClassName)
    meta.el.classList.add(meta.elClassName)

    switch (meta.type) {
        case "title":
        case "subtitle":
        case "blurb":
            pkg.container.append(meta.el)
            break;
        default:
            const {label, labelMsg} = buildLabel.call(this, pkg.field)
            label.classList.add(meta.elClassName)

            pkg.form.setField(pkg.field.type, meta.el, step)

            meta.el.addEventListener("change", () => {
                if (meta.type === "checkbox") {
                    pkg.form.setFormData(pkg.field.type, meta.el.checked)
                } else {
                    pkg.form.setFormData(pkg.field.type, meta.el.value)
                }
            })

            meta.el.addEventListener("input", () => {
                if (meta.type === "checkbox") {
                    pkg.form.setFormData(pkg.field.type, meta.el.checked)
                } else {
                    pkg.form.setFormData(pkg.field.type, meta.el.value)
                }

                const {validity} = meta.el
                if (!validity.valid) {
                    if (meta.type === "email" || meta.type === "tel" || meta.type === "text") {
                        if (validity.patternMismatch) {
                            labelMsg.textContent = ": " + meta.validationMessage.pattern
                        } else if (validity.tooShort) {
                            labelMsg.textContent = ": must be at least " + meta.el.minLength + " characters long"
                        }
                    }
                } else {
                    labelMsg.textContent = ""
                }
            })

            pkg.container.append(meta.el, label)

            if (meta.type !== "checkbox") {
                const template = document.createElement("template")
                template.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="${asteriskClass}" viewBox="0 0 24 24" height="10" width="10"></path> <path d="M12 12v-9"></path> <path d="M12 12l-9 -2.5"></path><path d="M12 12l9 -2.5"></path><path d="M12 12l6 8.5"></path><path d="M12 12l-6 8.5"></path></svg>`

                if (template.content.firstChild) {
                    pkg.container.append(template.content.firstChild)
                } else {
                    console.error("could not create svg element")
                }
            }
            break;
    }
}

const setOpts: {[key in MetaTypes]: (meta: Required<MetaMap[key]>) => void} = {
    date: <T extends Required<InputDateMeta>>(_: T) => {},
    blurb: <T extends Required<BlurbMeta>>(_: T,) => {},
    blurb2: <T extends Required<BlurbMeta>>(_: T,) => {},
    checkbox: <T extends Required<InputCheckboxMeta>>(_: T) => {},
    title: <T extends Required<TitleMeta>>(_: T) => {},
    subtitle: <T extends Required<SubtitleMeta>>(_: T) => {},
    text: <T extends Required<InputTextMeta>>(meta: T) => {
        meta.el.placeholder = ""
        meta.el.maxLength = meta.maxLength
        meta.el.minLength = meta.minLength
        meta.el.pattern = meta.pattern
    },
    email: <T extends Required<InputEmailMeta>>(meta: T) => {
        meta.el.placeholder = ""
        meta.el.maxLength = meta.maxLength
        meta.el.minLength = meta.minLength
        meta.el.pattern = meta.pattern
    },
    tel: <T extends Required<InputTelephoneMeta>>(meta: T) => {
        meta.el.placeholder = ""
        meta.el.maxLength = meta.maxLength
        meta.el.minLength = meta.minLength
        meta.el.pattern = meta.pattern
    },
    dropdown: <T extends Required<InputDropdownMeta>>(_: T) => {},
}

export type Pkg = {form: CustomForm, field: FormField, container: HTMLDivElement}

export function buildStuff(this: CustomForm, field: FormField, step: number) {
    const meta = buildMeta(field.type)

    const pkg: Pkg = {
        form: this,
        field,
        container: document.createElement("div"),
    }

    // todo: improve type safety
    // @ts-ignore -- this is okay, typescript is just getting confused because
    // meta should be inferred from the use of meta.type, but it is not
    meta.builder(meta, pkg)

    // todo: improve type safety
    // @ts-ignore -- this is okay, typescript is just getting confused because
    // meta should be inferred from the use of meta.type, but it is not
    setOpts[meta.type](meta)

    packageEl.call(this, meta, pkg, step)

    return pkg.container
}
