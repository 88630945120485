import {globalKeyframes, globalStyle, style} from "@vanilla-extract/css";

export const spinlipsisClass = style({
    boxSizing: "border-box",
    display: "inline-block",
    position: "relative",
    width: "60px",
})

globalStyle(`${spinlipsisClass} div`, {
    boxSizing: "border-box",
    position: "absolute",
    top: "50%",
    width: "8px",
    aspectRatio: "1/1",
    borderRadius: "50%",
    background: "currentColor",
    animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
})

globalStyle(`${spinlipsisClass} div:nth-child(1)`, {
    left: "6px",
    animation: "spinlipsis1 0.6s infinite",
})

globalStyle(`${spinlipsisClass} div:nth-child(2)`, {
    left: "6px",
    animation: `spinlipsis2 0.6s infinite`,
})

globalStyle(`${spinlipsisClass} div:nth-child(3)`, {
    left: "24px",
    animation: "spinlipsis2 0.6s infinite",
})

globalStyle(`${spinlipsisClass} div:nth-child(4)`, {
    left: "42px",
    animation: "spinlipsis3 0.6s infinite",
})

globalKeyframes("spinlipsis1", {
    "0%": { transform: "translateY(-50%) scale(0)"},
    "100%": {transform: "translateY(-50%) scale(1)"},
})

globalKeyframes("spinlipsis2", {
    "0%": { transform: "translateY(-50%) translate(0, 0)"},
    "100%": {transform: "translateY(-50%) translate(18px, 0)"},
})

globalKeyframes("spinlipsis3", {
    "0%": { transform: "translateY(-50%) scale(1)"},
    "100%": {transform: "translateY(-50%) scale(0)"},
})
