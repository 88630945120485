import {globalStyle, style} from '@vanilla-extract/css';
import {vars} from "./vars.css.js";

export const hideClass = style({
    display: "none !important",
});

export const errorClass = style({})
export const highlightClass = style({
    color: `var(${vars.color.fontPrimary})`
})

export const failMessage = style({
    paddingInline: "2em",
    paddingBlock: "1em",
})

export const formClass = style({
    vars: {
        [vars.color.formBg]: "#FFF",
        [vars.border.form]: "2px solid #000",
        [vars.border.closeBtn]: `var(${vars.border.form})`,
        [vars.shadow.form]: "unset",
        [vars.shadow.closeBtn]: "0 3px 6px rgb(0 0 0 / 16%)",
        [vars.radius.form]: "1.25em",
        [vars.padding.form]: "1.5em",
        [vars.font.size.formMain]: "14px",
        [vars.font.size.btn]: "16px",
        [vars.font.weight.btn]: "500",
        [vars.font.family.formMain]: "inherit",
        [vars.font.family.formPrimary]: `var(${vars.font.family.formMain})`,
        [vars.font.family.formSecondary]: `var(${vars.font.family.formMain})`,
        [vars.font.family.btn]: `var(${vars.font.family.formSecondary})`,
        [vars.font.lineHeight.title]: 'inherit',
        [vars.font.lineHeight.subtitle]: 'inherit',
        [vars.font.lineHeight.label]: '1.2',
        [vars.font.lineHeight.main]: 'inherit',

        [vars.margin.inner.blockEnd]: "1.5em",
        [vars.margin.title.blockEnd]: `var(${vars.margin.inner.blockEnd})`,
        [vars.margin.subtitle.blockEnd]: `var(${vars.margin.inner.blockEnd})`,

        [vars.width.btn]: "100%",
        [vars.height.btn]: `40px`,
        [vars.color.btnInvalidBg]: "rgba(0,0,0,0.1)",
        [vars.color.btnInvalid]: "#BBB",
        [vars.color.btnValidBg]: `var(${vars.color.formBgInner})`,
        [vars.color.btnValid]: "#000",
        [vars.color.btnBgHover]: "#43A047",
        [vars.color.btnHover]: "#FFF",
        [vars.radius.button]: `var(${vars.radius.formInner})`,
        [vars.border.subBtn]: `none`,

        [vars.color.formBgInner]: "#F7FBFC",
        [vars.color.formBgInnerActive]: "#FFF",
        [vars.border.fieldActive]: "1px solid #000",
        [vars.border.fieldInactive]: "1px solid #FEFEFE",
        [vars.color.fieldTextActive]: "#222",
        [vars.color.fieldTextInactive]: "#000",
        [vars.shadow.formInner]: "3px 3px 4px 2px rgba(0,0,0,0.2)",
        [vars.radius.formInner]: "0.35em",
        [vars.padding.formInner]: "0.75em 1em",

        [vars.width.marker]: "15px",
        [vars.border.markerRequired]: `var(${vars.thick.markerBorder}) solid var(${vars.color.markerRequired})`,
        [vars.color.markerRequiredBg]: "#FFF",
        [vars.color.markerRequired]: "#B00",
        [vars.thick.markerBorder]: "1px",

        [vars.color.markerInvalidBg]: "#B00",
        [vars.color.markerInvalid]: "#FFF",

        [vars.color.markerValidBg]: "#43A047",
        [vars.color.markerValid]: "#FFF",

        [vars.color.fontPrimary]: "#000",
        [vars.color.fontSecondary]: `var(${vars.color.fontPrimary})`,
        [vars.color.fontTertiary]: "#777",
        [vars.color.fontHighlight]: `var(${vars.color.fontPrimary})`,
        [vars.color.label]: `var(${vars.color.fontPrimary})`,

        [vars.width.checkbox]: "1.5em",
        [vars.margin.field.block]: "0.8em",
    },

    background: `var(${vars.color.formBg})`,
    border: `var(${vars.border.form})`,
    boxShadow: `var(${vars.shadow.form})`,
    borderRadius: `var(${vars.radius.form})`,
    fontSize: `var(${vars.font.size.formMain})`,
    fontFamily: `var(${vars.font.family.formMain})`,

    display: 'flex',
    position: "relative",
    maxWidth: "500px",
    minWidth: "250px",
    justifyContent: "center",
    lineHeight: `var(${vars.font.lineHeight.main})`,

    color: `var(${vars.color.fontPrimary})`,

    selectors: {
        [`:host([popup]) &`]: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // margin: "20px", // half the size of the close button

            // TODO
            "@media": {
                'screen and (max-width: 768px)': {
                    maxHeight: "calc(100% - 2em)",
                    maxWidth: "calc(100% - 2em)",
                    boxSizing: "border-box",
                    width: "100%",
                },
            },
        }
    }
});

globalStyle(`h1, h2, h3, h4, h5, h6`, {
    fontFamily: `var(${vars.font.family.formPrimary})`
})

globalStyle(`p, span, button, a`, {
    fontFamily: `var(${vars.font.family.formSecondary})`
})

globalStyle(`:host([popup="hide"])`, {
    display: "none",
})

globalStyle(`:host([popup])`, {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    pointerEvents: "all",
})

export const formStepClass = style({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `var(${vars.padding.form})`,
    margin: 1,

    selectors: {
        ":host([popup]) &": {
            "@media": {
                'screen and (max-width: 768px)': {
                    overflowY: "scroll",
                },
            }
        }
    }
})

export const endClass = style({
    textAlign: "center",
})

