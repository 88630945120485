import {Forward, isForward, isSource, Source, Stage, stageUrlMap} from "./types.script.js";
import {parseDomain} from "./util.js";
import {loadCss} from "./load.js";

// match "dev" on 2nd last part of script's src
export function initStage(script: HTMLScriptElement): Stage {
    const urlParts = script.src.split("/")
    return "dev" === urlParts[urlParts.length - 2] ? "dev" : "prod"
}

export function initSource(): Source {
    const customSource = script.getAttribute("source")
    if (customSource && isSource(customSource)) {
        return customSource
    }

    const host = window.location.hostname
    const hostParts = host.split(".")
    if (hostParts[0] === "ebooks") return "eb"
    if (hostParts[0].includes("learn")) return "lms"
    return "cs"
}

export function initUrl(stage: Stage) {
    return stageUrlMap[stage]
}

export function initForward(script: HTMLScriptElement): Forward | "" {
    const fwd = script.getAttribute("fwd")
    if (!fwd) return ""
    if (isForward(fwd)) return fwd
    throw new Error("fwd attribute in custom-form element is not set correctly")
}

export const storageNames = {
    data: "jmKZ3aMY4D4DeC",
    uuid: "zzfCaqUw9odbYZ"
}

export const script = <HTMLScriptElement>document.currentScript
export const isLocal = script.hasAttribute("local")

export const scriptStylePath = script.getAttribute("stylePath")
export const scriptStructPath = script.getAttribute("structPath")
export const scriptRedirect = script.getAttribute("redirect")
export const scriptTags = script.getAttribute("tags")
export const scriptSource = initSource()
export const scriptStage = initStage(script)
export const scriptDomain = parseDomain(window.location.hostname, scriptStage, isLocal)
export const scriptFwd = initForward(script)

export const serverUrl = initUrl(scriptStage)
export const mainCss = loadCss("main", scriptDomain, scriptStylePath)
