import {style} from '@vanilla-extract/css';
import {vars} from "./vars.css.js";
import {fieldContainerClass} from "./field.css.js";
import {flexCenter} from "./base.css.js";

export const subBtnClass = style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: `var(${vars.width.btn})`,
    minHeight: `var(${vars.height.btn})`,
    marginBlockStart: `0.7em`,
    padding: `var(${vars.padding.formInner})`,
    borderRadius: `var(${vars.radius.button})`,
    boxShadow: `var(${vars.shadow.formInner})`,
    background: `var(${vars.color.btnValidBg})`,
    color: `var(${vars.color.btnValid})`,
    cursor: "pointer",

    border: `var(${vars.border.subBtn})`,
    fontSize: `var(${vars.font.size.btn})`,
    fontWeight: `var(${vars.font.weight.btn})`,
    fontFamily: `var(${vars.font.family.btn})`,
    minWidth: "80px",
    transition: "250ms",

    selectors: {
        [`${fieldContainerClass}:has(:invalid) ~ &`]: {
            background: `var(${vars.color.btnInvalidBg})`,
            color: `var(${vars.color.btnInvalid})`,
            cursor: "not-allowed",
        },
        [`&:hover`]: {
            background: `var(${vars.color.btnBgHover})`,
            color: `var(${vars.color.btnHover})`,
        },
        [`${fieldContainerClass}:has(:invalid) ~ &:hover`]: {
            background: `var(${vars.color.btnInvalidBg})`,
            color: `var(${vars.color.btnInvalid})`,
        },
        [`&:not(:last-child)`]: {
            marginBlockEnd: "1.4em",
            "@media": {
                "screen and (max-width: 768px)": {
                    marginBlockEnd: "1em",
                }
            }
        }
    }
})

export const closeBtnClass = style({
    vars: {
        "--size-btn": "40px",
    },
    position: "absolute",
    width: "var(--size-btn)",
    aspectRatio: "1/1",
    zIndex: 2,
    borderRadius: "50%",
    background: "#FFF",
    top: "calc(var(--size-btn)/-2)",
    right: "calc(var(--size-btn)/-2)",
    color: "#B00",
    cursor: "pointer",
    boxSizing: "border-box",
    border: `var(${vars.border.closeBtn})`,
    boxShadow: `var(${vars.shadow.closeBtn})`,
    ...flexCenter,

    "@media": {
        'screen and (max-width: 768px)': {
            top: -10,
            right: -10,
            width: 30,
        },
    },

    selectors: {
        [`&:hover`]: {
            border: "3px solid #B00",
            boxShadow: "0 0 1px 1px rgba(0,0,0,0.5)",
        },
        [`&::before`]: {
            content: "",
            position: "absolute",
            width: "18px",
            height: "2px",
            background: `#B00`,
            transform: "rotate(45deg)"
        },
        [`&::after`]: {
            content: "",
            position: "absolute",
            width: "18px",
            height: "2px",
            background: `#B00`,
            transform: "rotate(-45deg)"
        }
    }
})