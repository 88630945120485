import {globalStyle, style} from "@vanilla-extract/css";
import {errorClass} from "./form.css.js";
import {checkboxClass} from "./field.css.js";
import {centerVertical, shift} from "./base.css.js";
import {vars} from "./vars.css.js";

/**
 * LABEL STYLES
 */
export const msgClass = style({
    selectors: {
        [`${errorClass} ~ label &`]: {
            color: "#F00",
            fontWeight: "700",
        },
        "input:placeholder-shown ~ label &": {
            display: "none",
        }
    }
})

// all none checkbox labels
globalStyle(`label:not(${checkboxClass})`, {
    ...centerVertical,
    lineHeight: `var(${vars.font.lineHeight.label})`,
    position: "absolute",
    background: `var(${vars.color.formBgInner})`,
    borderRadius: `var(${vars.radius.formInner})`,
    paddingInline: "0.5em",
    paddingBlockStart: "0.1em",
    transition: "transform 100ms, top 100ms",
    boxSizing: "border-box",
    userSelect: "none",
    left: "0.75em",
    cursor: "text",
    color: `var(${vars.color.label})`,
    "@media": {
        "screen and (max-width: 768px)": {
            padding: "unset",
            fontSize: `calc(var(${vars.font.size.formMain}) * 1.4)`,
        }
    }
})

// all error labels
globalStyle(`${errorClass} ~ label`, {
    border: "2px solid #F00",
    marginTop: "-2px",
})

// all focused input/select labels
globalStyle(`input:not(${checkboxClass}):focus ~ label`, {
    background: `var(${vars.color.formBgInnerActive})`,
})
globalStyle(`select:focus ~ label`, {
    background: `var(${vars.color.formBgInnerActive})`,
})

// all input labels
globalStyle(`input:not(${checkboxClass}) ~ label`, {
    pointerEvents: "none",
})

globalStyle(`select ~ label`, {
    pointerEvents: "none",
    cursor: "pointer",
})

globalStyle(`input[type="checkbox"] ~ label`, {
    marginInlineStart: "0.35em",
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    color: `var(${vars.color.fontSecondary})`,
    "@media": {
        "screen and (max-width: 768px)": {
            fontSize: `calc(var(${vars.font.size.formMain}) * 0.85) !important`,
        }
    }
})

// all shifted labels
globalStyle(`input:not(:placeholder-shown) ~ label:not(${checkboxClass}),
                    input:not(${checkboxClass}):focus ~ label,
                    ${errorClass} ~ label,
                    select:has(option:first-child:not(:checked)) ~ label`, {
    ...shift,
    "@media": {
        "screen and (max-width: 768px)": {
            paddingInline: "0.5em",
            paddingBlockStart: "0.1em",
            fontSize: `calc(var(${vars.font.size.formMain}) * 0.9) !important`,
        }
    }
})