import {buildButton, buildStuff} from "./build.js";
import {FormSubData, GHLTargets, ServerResponses, ValidationErrors} from "./types.server.js";
import {checkNever, isSite, isStrict, queryAll, queryEl, Sites} from "./util.js";
import {ButtonTypes, FormJsonV1, isFormButton, isFormJson} from "./types.formJson.js";
import {FieldKeys} from "./types.form.js";
import {
    mainCss,
    scriptDomain,
    scriptFwd,
    scriptRedirect,
    scriptSource,
    scriptStage,
    scriptStructPath,
    scriptStylePath,
    scriptTags,
    serverUrl,
    storageNames
} from "./init.js";
import {endClass, errorClass, failMessage, formClass, formStepClass, hideClass} from "./css/form.css.js";
import {spinlipsisClass} from "./css/spinlipsis.css.js";
import {loadCss, loadJson} from "./load.js";
import {
    blurbConClass,
    dropdownConClass,
    fieldContainerClass,
    subtitleConClass,
    titleConClass
} from "./css/field.css.js";
import {msgClass} from "./css/label.css.js";
import {closeBtnClass} from "./css/button.css.js";
import {Forward, isForward, isSource, Source} from "./types.script.js";

window.addEventListener("formDataLoad", ((e: CustomEvent) => {
    CustomForm.loadJson(e.detail as string)
    for (const form of CustomForm.forms) {
        form.handleFormLoad()
    }
}) as EventListener)

export class CustomForm extends HTMLElement {
    // noinspection JSUnusedGlobalSymbols
    static observedAttributes = ["test", "popup"];

    // static savedForm = localStorage.getItem(this.ctx.storageNames.data)
    static formData: { [key in FieldKeys]?: boolean | string }
    static formDataWasSet = false
    #stepData: { [key in FieldKeys]?: boolean | string }[] = []
    protected fields: {
        [key in FieldKeys]?: {
            step: number,
            el: HTMLInputElement | HTMLSelectElement
        }
    } = {}
    protected labelMessages: { [key in FieldKeys]?: HTMLSpanElement } = {}
    protected buttons: HTMLButtonElement[] = []
    protected steps: HTMLDivElement[] = []
    static forms: CustomForm[] = []
    protected formContainer: HTMLDivElement = document.createElement("div")
    static canSubmit = true
    static isSubmitting = false
    static submissionData: FormSubData
    static heartbeat: number

    static closeFormEvent = new CustomEvent('closeForm', {})
    static submitSuccess = new CustomEvent('cfSubmitSuccess', {})
    static authOkEvent: CustomEvent

    #stylePath: string | null | undefined
    #structPath: string | null | undefined
    #tags: string | null | undefined
    #redirectUrl: string | null | undefined
    #tagsArr: string[] = []
    #isStructLoadOk = false //will be set later in connected callback, but we'll default to false
    #ebName: string | null | undefined
    #ebDomain: string | null | undefined
    #ebUrl: string | null | undefined
    #target: GHLTargets = "all"
    #forward: Forward | "" | null | undefined
    #source: Source | null | undefined
    #calendar = false
    #canRedirect = false // sets to true after successful "next" button submission
    #test = false
    #canLog = false
    #loaded = false
    #serverUrl = new URL(serverUrl + "v1/webForm")
    #uuid = this.setUUID()
    #isSite = isSite(scriptDomain.root)
    #isStrict = this.#isSite ? isStrict(scriptDomain.root as Sites) : false
    #suppressDefault = () => this.hasAttribute("suppressDefault")
    #shadow = this.attachShadow({mode: "open"})
    #endMessage = "Thank you!<br/>Form submitted successfully."

    constructor() {
        super();
    }

    // noinspection JSUnusedGlobalSymbols
    async connectedCallback() {
        this.log("custom-form connected")

        if (!this.#loaded) {
            this.#stylePath = this.getAttribute("stylePath")
            this.#structPath = this.getAttribute("structPath")
            this.#tags = this.getAttribute("tags")
            this.#calendar = this.hasAttribute("calendar")
            this.#redirectUrl = this.getAttribute("redirect") ?? scriptRedirect
            this.#canLog = this.hasAttribute("log")
            this.#ebName = this.getAttribute("ebName")
            this.#ebDomain = this.getAttribute("ebDomain")
            this.#ebUrl = this.getAttribute("ebUrl")
            this.#forward = (() => {
                const fwd = this.getAttribute("fwd")
                if (!fwd) return ""
                if (isForward(fwd)) {
                    return fwd
                }
                console.error("fwd set on custom-form is not correct")
                this.appendUrlParam("errors", "unknown fwd")
                return ""
            })()
            this.#source = (() => {
                const customSource = this.getAttribute("source")
                if (customSource) {
                    if (isSource(customSource)) {
                        return customSource
                    }
                    console.error("source on custom-form not set correctly, defaulting to null")
                }
                return null
            })()
            const target = this.getAttribute("target")
            if (target && target === "agent" || target === "cs") this.#target = target

            CustomForm.forms.push(this);

            this.#shadow.appendChild(mainCss.cloneNode(true));

            // prefer path on custom-form with fallback to script
            const formStyle = loadCss("custom", scriptDomain, this.#stylePath ?? scriptStylePath)
            this.#shadow.appendChild(formStyle.cloneNode(true))

            // prefer path on custom-form with fallback to script
            const formStruct = await loadJson(
                scriptDomain,
                this.#structPath ?? scriptStructPath,
                this.#isStrict
            )

            this.#isStructLoadOk = formStruct.ok
            this.mountForm(formStruct.data)

            this.formContainer.id = "form"
            this.formContainer.classList.add(formClass)

            this.setTags()

            this.#loaded = true
        }
    }

    setFormData = (key: FieldKeys, value: string | boolean) => {
        CustomForm.formData[key] = value;
        localStorage.setItem(storageNames.data, JSON.stringify(CustomForm.formData));
        this.updateFormData(key, value)
    }

    updateFormData = (key: FieldKeys, value: string | boolean) => {
        for (const form of CustomForm.forms) {
            if (form !== this) {
                form.updateFormField(key, value)
            } else {
                const field = this.fields[key]
                if (!field) return
                this.setStepData(key, value, field.step)
            }
        }
    }

    setStepData = (key: FieldKeys, value: string | boolean, step: number) => {
        if (!this.#stepData[step]) this.#stepData[step] = {}
        this.#stepData[step][key] = value;
    }

    updateFormField = (key: FieldKeys, value: string | boolean) => {
        const field = this.fields[key]
        if (!field) return

        if (field.el instanceof HTMLSelectElement) {
            field.el.value = typeof value === "string" ? value : ""
            this.setStepData(key, field.el.value, field.step)
        } else {
            if ("checkbox" === field.el.type) {
                field.el.checked = !!value
                this.setStepData(key, field.el.checked, field.step)
            } else {
                field.el.value = value.toString()
                this.setStepData(key, field.el.value, field.step)
            }
        }

    }

    setUUID() {
        const sessionFormUUID = this.#structPath
            ? `${storageNames.uuid}-${this.#structPath}`
            : scriptStructPath
                ? `${storageNames.uuid}-${scriptStructPath}`
                : storageNames.uuid

        const store = sessionStorage.getItem(sessionFormUUID)
        if (store) {
            return store
        } else {
            const session = crypto.randomUUID()
            sessionStorage.setItem(sessionFormUUID, session)
            return session
        }
    }

    setField = (key: FieldKeys, el: HTMLInputElement | HTMLSelectElement, step: number) => {
        this.fields[key] = {step, el}
    }

    preValidateSubmit(stepNum: number) {
        if (!CustomForm.canSubmit || CustomForm.isSubmitting) return false
        const list = queryAll("div", `.${fieldContainerClass}`, this.steps[stepNum])
        for (const item of list) {
            if (item.classList.contains(blurbConClass)
                || item.classList.contains(titleConClass)
                || item.classList.contains(subtitleConClass)) continue

            const input =
                queryEl(item.classList.contains(dropdownConClass) ? "select" : "input", "", item)

            if (!input.checkValidity()) return false
        }
        return true
    }

    getStepData(stepNum: number): { [key in FieldKeys]?: boolean | string } {
        const data: { [key in FieldKeys]?: boolean | string } = {}
        for (let i = 0; i <= stepNum; i++) {
            Object.assign(data, this.#stepData[i])
        }
        return data
    }

    handleNextBtn = async (stepNum: number) => {
        const response = await this.handleHumanSubmission.call(this, stepNum, "next")
        if (!response) return

        CustomForm.heartbeat = setInterval(() => this.submitForm(), 600000) as unknown as number

        this.steps[stepNum].classList.toggle(hideClass)
        this.steps[stepNum + 1].classList.toggle(hideClass)
    }

    handleSubmitBtn = async (stepNum: number) => {
        const response = await this.handleHumanSubmission.call(this, stepNum, "submit")
        if (!response) return

        this.style.minHeight = "unset"
        this.steps[stepNum].innerHTML = `<h3 class="${endClass}">${this.#endMessage}</h3>`

        setTimeout(() => {
            this.dispatchEvent(CustomForm.closeFormEvent)
        }, 5000)
    }

    setTags = () => {
        let tags: string = ""

        if (scriptTags) {
            tags += scriptTags
        }

        if (this.#tags) {
            if (tags) tags += ","
            tags += this.#tags
        }

        if (tags) {
            this.#tagsArr = tags.split(",")
        }
    }

    appendUrlParam(key: string, value: string) {
        const current = this.#serverUrl.searchParams.get(key)
        if (current) {
            if (current.includes(value)) return
            this.#serverUrl.searchParams.set(key, current + "," + value)
        } else {
            this.#serverUrl.searchParams.set(key, value)
        }
    }

    async handleHumanSubmission(stepNum: number, buttonType: ButtonTypes): Promise<true | void> {
        if (!this.preValidateSubmit(stepNum)) return

        CustomForm.submissionData = {
            step: buttonType,
            source: this.#source ?? scriptSource,
            target: this.#target,
            uuid: this.#uuid,
            fwd: this.#forward ?? scriptFwd,
            tags: this.#tagsArr,
            formUrl: window.location.toString(),
            ...this.getStepData(stepNum)
        }

        if ("eb" === CustomForm.submissionData.source) {
            if (!this.#ebName || !this.#ebUrl || !this.#ebDomain) {
                this.appendUrlParam("errors", "ebookDataMissing")
                CustomForm.submissionData.bookName = this.#ebName ?? "missing"
                CustomForm.submissionData.bookUrl = this.#ebUrl
                    ? this.#ebUrl.includes("https://")
                        ? this.#ebUrl
                        : "https://" + this.#ebUrl
                    : window.location.href
                CustomForm.submissionData.bookDomain = this.#ebDomain ?? window.location.hostname
            } else {
                CustomForm.submissionData.bookName = this.#ebName
                CustomForm.submissionData.bookUrl = this.#ebUrl.includes("https://") ? this.#ebUrl : "https://" + this.#ebUrl
                CustomForm.submissionData.bookDomain = this.#ebDomain
            }
        }

        this.buttons[stepNum].textContent = " "
        this.buttons[stepNum].innerHTML = `<div class="${spinlipsisClass}"><div></div><div></div><div></div><div></div></div>`

        const response = await this.submitForm()

        if (!response.ok) {
            // display error messages
            switch (response.cause) {
                case "unknown":
                case "malformed request":
                    this.displayFailure(stepNum, "An error occurred while submitting the form", "Please try again later")
                    return
                case "validation errors":
                    this.displayValidationErrors(stepNum, response.errors)
                    return
                case "unsupported zip code":
                    this.displayValidationErrors(stepNum, [{field: "zip", reason: "is not valid"}])
                    return
                case "ebook error":
                    if (buttonType === "submit") {
                        this.displayFailure(stepNum, response.header, response.message)
                        return
                    }
                    return true
                default:
                    checkNever(response)
                    return
            }
        }

        if ("hp" === response.type) {
            // display form submitted successfully as the honey pot was triggered
            this.displaySubmitted(stepNum)
            return
        }

        if ("eb" === response.type) {
            if (response.allowed) {
                console.log("authOk sent")
                CustomForm.authOkEvent = new CustomEvent("authOk", {
                    detail: CustomForm.formData.email
                })
                this.dispatchEvent(CustomForm.authOkEvent)
            } else if ("submit" === buttonType && response.displayToUser) {
                this.displayFailure(stepNum, "Thank you for your submission!", "One of our agents will reach out to discuss how you can get access to this ebook.")
                // todo
                clearInterval(CustomForm.heartbeat)
                return
            }
        }

        if ("submit" === buttonType) {
            setTimeout(() => {
                if (this.#redirectUrl) this.redirectToUrl(this.#redirectUrl)
            }, 1000)
            window.dispatchEvent(CustomForm.submitSuccess)
        } else { // allow the user to be redirected on successful "next" submission
            this.#canRedirect = true
        }

        clearInterval(CustomForm.heartbeat)
        return true
    }

    async submitForm(): Promise<ServerResponses> {
        CustomForm.isSubmitting = true
        try {
            if (this.#test) this.#serverUrl.searchParams.set("test", "true")
            if (this.#calendar) this.#serverUrl.searchParams.set("calendar", "send")

            if (!this.#isSite) {
                this.appendUrlParam("errors", "unknownUrl")
            }
            if (!this.#isStructLoadOk && !(this.#test && this.#suppressDefault)) {
                this.appendUrlParam("default", "true")
            }

            const fetchOptions: RequestInit = {
                method: "POST",
                body: JSON.stringify(CustomForm.submissionData)
            }

            const request = await fetch(this.#serverUrl, fetchOptions)
            CustomForm.isSubmitting = false
            return await request.json() as unknown as ServerResponses;
        } catch (e) {
            this.error(e)
            CustomForm.canSubmit = false
            CustomForm.isSubmitting = false
            return {
                ok: false,
                cause: "unknown"
            }
        }
    }

    displaySubmitted(stepNum: number) {
        CustomForm.canSubmit = false
        const submittedMessage = document.createElement("h3")
        submittedMessage.textContent = "Form submitted successfully"

        for (const form of CustomForm.forms) {
            form.steps[stepNum].classList.add(hideClass)
            form.formContainer.appendChild(submittedMessage.cloneNode(true))
        }
    }

    displayFailure(stepNum: number, h: string, p: string) {
        CustomForm.canSubmit = false
        const failContainer = document.createElement("div")
        failContainer.classList.add(failMessage)
        const failureMessage = document.createElement("h3")
        failureMessage.textContent = h
        const failureSubMessage = document.createElement("p")
        failureSubMessage.textContent = p

        failContainer.append(failureMessage, failureSubMessage)

        for (const form of CustomForm.forms) {
            form.steps[stepNum].classList.add(hideClass)
            form.formContainer.append(failContainer.cloneNode(true))
        }
    }

    unmountError = (field: HTMLInputElement | HTMLSelectElement) => {
        if (field.checkValidity()) {
            field.classList.remove(errorClass)
            const parent = field.parentElement
            if (!parent) throw new Error("cannot find parent of error field")
            const el = queryEl("span", `.${msgClass}`, parent)
            el.textContent = ""
        }
    }

    displayValidationErrors(stepNum: number, errors: ValidationErrors) {
        this.buttons[stepNum].innerHTML = "Correct the above, and try again"
        for (const error of errors) {
            const field = this.fields[error.field]
            if (!field) {
                this.warn("field should have error but it is missing")
                continue
            }

            field.el.classList.add(errorClass)
            field.el.addEventListener("input", this.unmountError.bind(this, field.el))

            const msg = this.labelMessages[error.field]
            if (msg) msg.textContent = " " + error.reason
        }
    }

    static formJson: FormJsonV1

    static loadJson(data: string) {
        try {
            const parsed = JSON.parse(data)
            if (!isFormJson(parsed)) {
                console.error("Invalid form JSON", data)
                return
            }
            CustomForm.formJson = parsed
        } catch (e) {
            console.error(e)
            return
        }
    }

    handleFormLoad() {
        this.mountForm(CustomForm.formJson)
    }

    buildCloseBtn = (className: string) => {
        const closeBtn = document.createElement("button")
        closeBtn.classList.add(className)
        // closeBtn.textContent = "+"
        closeBtn.addEventListener("pointerdown", () => this.dispatchEvent(CustomForm.closeFormEvent))
        return closeBtn
    }

    redirectToUrl = (url: string) => {
        window.location.href = url.includes("https://") ? url : "https://" + url
    }

    mountForm = (json: FormJsonV1) => {
        for (let i = 0; i < json.steps.length; i++) {
            const fieldContainer = document.createElement("div")
            fieldContainer.id = "step-" + i

            if (i !== 0) {
                fieldContainer.classList.add(formStepClass, hideClass)
            } else {
                // attach honey pot on the first step of all forms
                fieldContainer.append(buildStuff.call(this, {type: "homePageUrl"}, i))
                fieldContainer.classList.add(formStepClass)
            }

            for (const input of json.steps[i].fields) {
                if (isFormButton(input)) {
                    if (json.v !== 0) {
                        fieldContainer.append(buildButton.call(this, input, i))
                    }
                } else {
                    fieldContainer.append(buildStuff.call(this, input, i))
                }
            }

            this.formContainer.append(fieldContainer)
            this.steps.push(fieldContainer)
        }

        if (this.hasAttribute("popup")) {
            this.formContainer.append(this.buildCloseBtn(closeBtnClass))
            this.addEventListener("closeForm", () => {
                this.setAttribute("popup", "hide")
                if (this.#canRedirect && this.#redirectUrl) {
                    this.redirectToUrl(this.#redirectUrl)
                }
            })
        }

        if (!CustomForm.formDataWasSet) {
            CustomForm.formData = getLocalStorage()
        }

        for (const [k, v] of Object.entries(CustomForm.formData)) {
            this.updateFormField(k as FieldKeys, v)
        }

        if (json.end) this.#endMessage = json.end

        this.#shadow.appendChild(this.formContainer)
    }

    // noinspection JSUnusedGlobalSymbols
    disconnectedCallback() {
        this.log("custom-form disconnected");
    }

    // noinspection JSUnusedGlobalSymbols
    adoptedCallback() {
        this.log("custom-form adopted");
    }

    // noinspection JSUnusedGlobalSymbols
    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        if (oldValue === null) {
            if (newValue !== null) {
                this.log(`Attribute ${name} added ${newValue ? `with value: ${newValue}` : "without a value"}`);
            }
        } else {
            if (newValue === null) {
                this.log(`Attribute ${name} ${oldValue ? `with value ${oldValue} ` : ""}removed.`);
            } else if (oldValue !== newValue) {
                this.log(`Attribute ${name} changed from ${oldValue} to ${newValue}`);
            }
        }

        if ("test" === name) this.#test = this.hasAttribute("test")
        if ("popup" === name) {
            if (newValue === "show") {
            } else {
            }
        }
    }

    log(msg: unknown) {
        if (scriptStage === "prod" && !this.#canLog) return
        console.log(msg)
    }

    error(msg: unknown) {
        if (scriptStage === "prod" && !this.#canLog) return
        console.error(msg)
    }

    warn(msg: unknown) {
        if (scriptStage === "prod" && !this.#canLog) return
        console.warn(msg)
    }
}

customElements.define("custom-form", CustomForm);

export function getLocalStorage() {
    const saved = localStorage.getItem(storageNames.data)
    if (saved) {
        return JSON.parse(saved) as { [key in FieldKeys]?: boolean | string }
    } else {
        return {} as { [key in FieldKeys]?: boolean | string }
    }
}