import {Stage} from "./types.script.js";

export function checkNever(obj: never) {
    throw new Error("never", obj)
}

export function queryEl<K extends keyof HTMLElementTagNameMap>(elType: K, selector: string, searchFrom?: HTMLElement): HTMLElementTagNameMap[K] {
    let el: unknown

    if (!searchFrom) {
        el = document.querySelector(`${elType}${selector}`)
    } else {
        el = searchFrom.querySelector(`${elType}${selector}`)
    }
    if (!el) throw new Error("could not query element: " + elType + selector)

    return el as HTMLElementTagNameMap[K]
}

export function queryAll<K extends keyof HTMLElementTagNameMap>(elType: K, selector: string, searchFrom?: HTMLElement): NodeListOf<HTMLElementTagNameMap[K]> {
    let list: unknown

    if (!searchFrom) {
        list = document.querySelectorAll(`${elType}${selector}`)
    } else {
        list = searchFrom.querySelectorAll(`${elType}${selector}`)
    }
    if (!list) throw new Error("could not query element list: " + elType + selector)

    return list as NodeListOf<HTMLElementTagNameMap[K]>
}

export type DomainParts = {
    root: string,
    mainHost: string,
    customHost: string,
    sub: string[]
}
export function parseDomain(domain: string, stage: Stage, isLocal: boolean): DomainParts {
    const formattedDomain = domain
        .toLowerCase()
        .trim()
        .replaceAll(/(http:\/\/)|(https:\/\/)|(www\.)|(\/.+)/g, "")

    const domainParts = formattedDomain.split(".")
    const subParts: string[] = []
    const rootDomain: string[] = []

    for (let i = 0; i < domainParts.length; i++) {
        if (i >= domainParts.length - 2) {
            rootDomain.push(domainParts[i])
        } else {
            subParts.push(domainParts[i])
        }
    }

    const root = rootDomain.join(".")

    // we're going to have two locations: main & custom
    let mainHost: string
    let customHost: string
    if (!isLocal) {
        mainHost = "https://d109c0cv36f9gg.cloudfront.net/custom-forms"
        customHost = `https://edge.${root}/custom-forms`
    } else {
        const localRoot = `https://local.${root}`
        mainHost = `${localRoot}/dist`
        customHost = `${localRoot}/html`
    }

    if ("dev" === stage) mainHost += "/dev"

    return {
        root, mainHost, customHost,
        sub: subParts
    }
}

export type GoodReturn<T> = {
    ok: true,
    data: T
}

export type BadReturn<T> = {
    ok: false,
    data: T
}

export type CheckReturn<T> = {
    ok: boolean,
    data: T
}

// All AMED/PMED sites need both disclosures to be explicitly checked for full assignment
const siteDisclosureStatus = {
    "explore-medicare.org":                          "strict",
    "medicare-parts-explained.com":                  "strict",
    "what-is-medicare.com":                          "strict",
    "medicare-advantage-vs-medicare-supplement.com": "strict",
    "bedrockfs.com":                                 "relaxed",
    "certifiedsafemoney.com":                        "relaxed",
    "federalretirement.news":                        "relaxed",
    "indexeduniversal.life":                         "relaxed",
    "modernbusinessleaders.com":                     "relaxed",
    "todaysmanagers.com":                            "relaxed",
    "winning-entrepreneur.com":                      "relaxed",
    "postal-service-health-benefits.com":            "strict",
    "what-is-pshb.com":                              "strict",
    "usps-health-benefits-plan.com":                 "strict",
    "pshb.info":                                     "strict",
    "psreducators.com":                              "relaxed",
    "psretirement.com":                              "relaxed",
    "best-real-estate-agent-near-me.com":            "relaxed",
    "buy-a-home.pro":                                "relaxed",
    "real-estate-investor.news":                     "relaxed",
    "sellmyhome.reviews":                            "relaxed",
    "toprealestatesagent.com":                       "relaxed",
    "retirement.news":                               "relaxed",
} as const
export type Sites = keyof typeof siteDisclosureStatus

export function isSite(domain: string): domain is Sites {
    // @ts-ignore -- we're safe to ignore this as we're not going to use the value
    return siteDisclosureStatus[domain] !== undefined
}

export function isStrict(domain: Sites): boolean {
    const mode = siteDisclosureStatus[domain];
    return "strict" === mode
}